<template>
  <div class="wrapper" v-if="lobbyDetails">
    <Header :to="'leaderboard'" v-if="!lobbyDetails.no_games" />
    <NoExpsToday v-if="lobbyDetails.no_games" />
    <AllExpsDone v-if="!lobbyDetails.leaderboard && lobbyDetails.games_done" />
    <div class="bg-black" v-else>
      <div class="flex flex-col place-items-center mt-10" v-if="lobbyDetails">
        <div class="relative w-full px-6">
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/platform/common/donut.webp"
            width="80"
            height="80"
            class="absolute -top-10 left-6 z-10"
          />
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/platform/common/bacon.webp"
            width="65"
            height="65"
            class="absolute -bottom-4 right-4 z-10"
          />
          <div
            class="w-full border border-nColorDarkGray bg-nColorOrange rounded-xl triple-border p-4"
          >
            <div id="gameStartTimerWrapper" v-if="lobbyDetails.next_game">
              <div
                class="font-ZuumeSemiBold text-4xl text-nColorYellow text-shadow-black-right uppercase text-center"
              >
                Games go live in
              </div>
              <div class="flex justify-center">
                <div
                  class="timerBox shadow-black-right relative bg-nColorYellow border border-black rounded-xl py-2 text-center w-5/6"
                >
                  <span
                    id="gameStartTimer"
                    class="font-ZuumeSemiBold text-5xl text-black"
                    >00<span class="font-ZuumeSemiBold text-xl">h</span>:00<span
                      class="font-ZuumeSemiBold text-xl"
                      >m</span
                    >:00<span class="font-ZuumeSemiBold text-xl">s</span></span
                  >
                </div>
              </div>
              <p class="font-InterExtraBold text-xs text-center mt-2">
                Play multiple times.<br />
                Highest score is considered.
              </p>
            </div>
            <div id="gameEndTimerWrapper" v-else-if="lobbyDetails.current_game">
              <div
                class="font-ZuumeSemiBold text-4xl text-nColorYellow text-shadow-black-right uppercase text-center"
              >
                Games end in
              </div>
              <div class="flex justify-center">
                <div
                  class="timerBox shadow-black-right relative bg-nColorYellow border border-black rounded-xl py-2 text-center w-5/6"
                >
                  <span
                    id="gameEndTimer"
                    class="font-ZuumeSemiBold text-5xl text-black"
                    >00<span class="font-ZuumeSemiBold text-xl">h</span>:00<span
                      class="font-ZuumeSemiBold text-xl"
                      >m</span
                    >:00<span class="font-ZuumeSemiBold text-xl">s</span></span
                  >
                </div>
              </div>
              <p class="font-InterExtraBold text-xs text-center mt-2">
                Play multiple times.<br />
                Highest score is considered.
              </p>
            </div>
            <div
              id="leaderboardTimerWrapper"
              v-else-if="lobbyDetails.leaderboard"
            >
              <div
                class="font-ZuumeSemiBold text-4xl text-nColorYellow text-shadow-black-right uppercase text-center"
              >
                Results announced in
              </div>
              <div class="flex justify-center">
                <div
                  class="timerBox shadow-black-right relative bg-nColorYellow border border-black rounded-xl py-2 text-center w-5/6"
                >
                  <span
                    id="leaderboardTimer"
                    class="font-ZuumeSemiBold text-5xl text-black"
                    >00<span class="font-ZuumeSemiBold text-xl">h</span>:00<span
                      class="font-ZuumeSemiBold text-xl"
                      >m</span
                    >:00<span class="font-ZuumeSemiBold text-xl">s</span></span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full px-4 mt-4" v-if="lobbyDetails.has_category">
          <div class="grid grid-cols-2 gap-2" v-if="!showGames">
            <div
              v-for="cat in lobbyDetails.games"
              :key="cat"
              @click="showGamesForCategory(cat)"
            >
              <div>
                <img :src="cat.category_icon" />
              </div>
            </div>
          </div>
          <div class="w-full" v-if="showGames">
            <div class="flex items-center w-full py-2">
              <div class="w-1/12">
                <img
                  :src="require('@/assets/images/navigate-back.svg')"
                  @click="hideGamesAndShowCategories()"
                />
              </div>
              <div
                class="w-10/12 text-nColorYellow font-ZuumeBold text-4xl text-shadow-orange-right-small text-center"
              >
                {{ selectedCategory.category_name }}
              </div>
            </div>
            <div
              v-for="game in selectedCategoryGames"
              :key="game"
              :class="{
                'bg-black text-nColorYellow border border-nColorYellow':
                  lobbyDetails.next_game,
                'bg-nColorYellow border border-black':
                  lobbyDetails.current_game ||
                  lobbyDetails.leaderboard ||
                  lobbyDetails.games_done,
              }"
              class="mb-2 p-2 rounded-2xl shadow-orange-right"
              @click.prevent="
                (lobbyDetails.current_game ||
                  lobbyDetails.leaderboard ||
                  lobbyDetails.games_done) &&
                  goToGame(game.slug, game.schedule_id)
              "
            >
              <div class="flex justify-between items-center">
                <div class="flex gap-2 items-center">
                  <div class="flex justify-center items-center">
                    <img :src="game.icon" class="w-10" />
                  </div>
                  <div class="font-ZuumeSemiBold text-xl">
                    {{ game.name }}
                  </div>
                </div>
                <div v-if="lobbyDetails.current_game">
                  <img
                    :src="require('@/assets/images/right-arrow.svg')"
                    class="w-4 h-4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full px-4 mt-4" v-else>
          <div
            v-for="game in lobbyDetails.games"
            :key="game"
            :class="{
              'bg-black text-nColorYellow border border-nColorYellow':
                lobbyDetails.next_game,
              'bg-nColorYellow border border-black':
                lobbyDetails.current_game ||
                lobbyDetails.leaderboard ||
                lobbyDetails.games_done,
            }"
            class="mb-2 p-2 rounded-2xl shadow-orange-right"
            @click.prevent="
              (lobbyDetails.current_game ||
                lobbyDetails.leaderboard ||
                lobbyDetails.games_done) &&
                goToGame(game.slug, game.schedule_id)
            "
          >
            <div class="flex justify-between items-center">
              <div class="flex gap-2 items-center">
                <div class="flex justify-center items-center">
                  <img :src="game.icon" class="w-10" />
                </div>
                <div class="font-ZuumeSemiBold text-xl">
                  {{ game.name }}
                </div>
              </div>
              <div v-if="lobbyDetails.current_game">
                <img
                  :src="require('@/assets/images/right-arrow.svg')"
                  class="w-4 h-4"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            lobbyDetails &&
            lobbyDetails.games &&
            rewardPartners &&
            rewardPartners.length > 0
          "
        >
          <p
            class="text-white font-InterSemiBold text-sm px-4 py-2 mt-2 text-center"
          >
            Reward Partners
          </p>
          <div
            :class="{
              'flex justify-center': rewardPartners.length === 1,
              'grid grid-cols-4 gap-3 px-4': rewardPartners.length !== 1,
            }"
          >
            <div
              v-for="partner in rewardPartners"
              :key="partner"
              class="flex justify-center mt-1"
            >
              <img
                :src="partnerImage(partner)"
                alt="Reward Partners"
                class="w-20 h-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import NoExpsToday from "@/components/emptystates/NoExpsToday.vue";
import Header from "@/components/elements/Header.vue";
import AllExpsDone from "@/components/emptystates/AllExpsDone.vue";

export default {
  name: "Lobby",
  components: { NoExpsToday, AllExpsDone, Header },
  data() {
    return {
      businessSlug: this.$store.getters.businessSlug,
      freebie: "",
      lobbyDetails: null,
      game: null,
      startDateTime: null,
      cutoffDateTime: null,
      leaderboardDateTime: null,
      timerStarted: false,
      timerIntervalId: null,
      showGames: false,
      selectedCategory: null,
      selectedCategoryGames: [],
      rewardPartners: [],
    };
  },
  created() {
    ApiService.get(apiResource.getLobbyDetails, {
      business_slug: this.$route.params.business,
    }).then((data) => {
      this.lobbyDetails = data.data.data;

      var start,
        cut,
        leaderboard = null;

      if (this.lobbyDetails.has_category) {
        start = this.lobbyDetails.games[0].games[0].start_datetime;
        cut = this.lobbyDetails.games[0].games[0].cutoff_datetime;
        leaderboard = this.lobbyDetails.games[0].games[0].leaderboard_datetime;
        this.rewardPartners =
          this.lobbyDetails.games[0].games[0].reward_partners;
      } else if (this.lobbyDetails.games.length > 0) {
        start = this.lobbyDetails.games[0].start_datetime;
        cut = this.lobbyDetails.games[0].cutoff_datetime;
        leaderboard = this.lobbyDetails.games[0].leaderboard_datetime;
        this.rewardPartners = this.lobbyDetails.games[0].reward_partners;
      }

      if (this.lobbyDetails.current_game) {
        this.setEndDateTime(cut);
      } else if (this.lobbyDetails.next_game) {
        this.setStartDateTime(start);
      } else if (this.lobbyDetails.leaderboard) {
        this.setLeaderboardDateTime(leaderboard);
      }

      if (this.lobbyDetails.game) {
        this.game = this.lobbyDetails.game;
      }
      if (this.lobbyDetails.details && this.lobbyDetails.details.freebies) {
        if (this.lobbyDetails.details.freebies.freebie_type === "bpcl") {
          this.freebie = this.lobbyDetails.details.freebies.freebie_details;
        } else if (
          this.lobbyDetails.details.freebies.freebie_type === "event"
        ) {
          this.freebie = " freebies";
        }
      }
    });
  },
  mounted() {
    setTimeout(() => {
      if (this.lobbyDetails.next_game) {
        this.setTimer("gameStartTimer");
      } else if (this.lobbyDetails.current_game) {
        this.setTimer("gameEndTimer");
      } else if (this.lobbyDetails.leaderboard) {
        this.setTimer("leaderboardTimer");
      }
      // var webPushrBell = document.getElementById("webpushr-bell-optin");
      // if (webPushrBell) {
      //   webPushrBell.style.display = "block";
      // }
      // var webPushrPrompt = document.getElementById("webpushr-prompt-wrapper");
      // if (webPushrPrompt) {
      //   webPushrPrompt.style.display = "block";
      // }
      // var vue = this;
      // if (typeof window.webpushr === "function") {
      //   window.webpushr("fetch_id", function (sid) {
      //     if (sid) {
      //       ApiService.post(apiResource.saveWebPushSid, {
      //         web_push_sid: sid,
      //         business_slug: vue.$route.params.business,
      //       });
      //     }
      //   });
      // }
    }, 1000);
  },
  // beforeRouteLeave(to, from, next) {
  //   var webPushrBell = document.getElementById("webpushr-bell-optin");
  //   var webPushrPrompt = document.getElementById("webpushr-prompt-wrapper");
  //   if (webPushrBell) {
  //     document.getElementById("webpushr-bell-optin").style.display = "none";
  //   }
  //   if (webPushrPrompt) {
  //     document.getElementById("webpushr-prompt-wrapper").style.display = "none";
  //   }
  //   next();
  // },
  beforeUnmount() {
    clearInterval(this.timerIntervalId);
    this.timerStarted = false;
  },
  computed: {
    gridClass() {
      return this.lobbyDetails.details.reward_partners === 1
        ? "grid place-items-center"
        : "grid grid-cols-3";
    },
  },
  methods: {
    showGamesForCategory(category) {
      this.selectedCategory = category;
      this.setGamesForCategory(category);
      this.showGames = true;
    },
    hideGamesAndShowCategories() {
      this.selectedCategory = null;
      this.selectedCategoryGames = null;
      this.showGames = false;
    },
    setGamesForCategory(category) {
      for (const cat of this.lobbyDetails.games) {
        if (cat.category_slug === category.category_slug) {
          this.selectedCategoryGames = cat.games;
          return;
        }
      }
      return;
    },
    setTimer(timerElementName) {
      const timerElement = document.getElementById(timerElementName);
      this.timerIntervalId = setInterval(() => {
        const data = this.getTimeCalculation();
        timerElement.innerHTML = `${data.hours}<span class="font-ZuumeSemiBold text-xl">h</span>:${data.minutes}<span class="font-ZuumeSemiBold text-xl">m</span>:${data.seconds}<span class="font-ZuumeSemiBold text-xl">s</span>`;
        if (data.remainingTime <= 0) {
          clearInterval(this.timerElement);
          window.location.reload();
        }
      }, 1000);
    },
    getRotatedAlphabet(char) {
      const rotatedAlphabet = String.fromCharCode(char.charCodeAt(0) + 1);
      return `<span class="rotated-alphabet">${rotatedAlphabet}</span>`;
    },
    setStartDateTime(dateTime) {
      const dateTimeString = dateTime;
      const [dateString, timeString] = dateTimeString.split("T");
      const [year, month, day] = dateString.split("-");
      const formattedDate = `${year}-${month}-${day}`;
      const [hour, minute, second] = timeString.split(":");
      this.startDateTime = new Date(
        `${formattedDate}T${hour}:${minute}:${second}`
      );
      // Add a 3 second delay to the startDateTime
      this.startDateTime.setSeconds(this.startDateTime.getSeconds() + 3);
    },
    setEndDateTime(dateTime) {
      const dateTimeString = dateTime;
      const [dateString, timeString] = dateTimeString.split("T");
      const [year, month, day] = dateString.split("-");
      const formattedDate = `${year}-${month}-${day}`;
      const [hour, minute, second] = timeString.split(":");
      this.cutoffDateTime = new Date(
        `${formattedDate}T${hour}:${minute}:${second}`
      );
      // Add a 3 second delay to the startDateTime
      this.cutoffDateTime.setSeconds(this.cutoffDateTime.getSeconds() + 3);
    },
    setLeaderboardDateTime(dateTime) {
      const dateTimeString = dateTime;
      const [dateString, timeString] = dateTimeString.split("T");
      const [year, month, day] = dateString.split("-");
      const formattedDate = `${year}-${month}-${day}`;
      const [hour, minute, second] = timeString.split(":");
      this.leaderboardDateTime = new Date(
        `${formattedDate}T${hour}:${minute}:${second}`
      );
      // Add a 3 second delay to the startDateTime
      this.leaderboardDateTime.setSeconds(
        this.leaderboardDateTime.getSeconds() + 3
      );
    },
    getTimeCalculation() {
      var dateTimeToConsider = this.startDateTime;
      if (this.lobbyDetails.current_game) {
        dateTimeToConsider = this.cutoffDateTime;
      } else if (this.lobbyDetails.leaderboard) {
        dateTimeToConsider = this.leaderboardDateTime;
      }
      const currentTime = new Date();
      const remainingTime = Math.max(0, dateTimeToConsider - currentTime);
      const hours = Math.floor(remainingTime / (1000 * 60 * 60)).toLocaleString(
        "en-US",
        { minimumIntegerDigits: 2 }
      );
      const minutes = Math.floor(
        (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });
      const seconds = Math.floor(
        (remainingTime % (1000 * 60)) / 1000
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });
      return {
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        remainingTime: remainingTime,
      };
    },
    goToGame(game, scheduleId) {
      this.$router.push({
        name: "Game",
        params: {
          business: this.$route.params.business,
          schedule_id: scheduleId,
          game: game,
        },
      });
    },
    goToLeaderboard() {
      this.$router.push({
        name: "Leaderboard",
        params: {
          business: this.$route.params.business,
          exp: this.lobbyDetails.prev_timeslot.exp_slug,
          schedule_id: this.lobbyDetails.prev_timeslot.schedule_id,
        },
      });
    },
    replaceFreebie(rule) {
      if (rule.includes("[FREEBIE]")) {
        return rule.replace("[FREEBIE]", this.freebie);
      } else {
        return rule;
      }
    },
    formattedTime(timeToFormat) {
      const [hours, minutes] = timeToFormat.split(":");
      const formattedHours = hours % 12 || 12;
      const period = hours < 12 ? "AM" : "PM";
      return `${formattedHours}:${minutes} ${period}`;
    },
    partnerImage(partner) {
      return (
        "https://playda.s3.ap-south-1.amazonaws.com/assets/brands/" +
        partner +
        ".png"
      );
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 100dvh;
}
.rotated-alphabet {
  display: inline-block;
  transform: rotate(-11.27deg);
  -webkit-transform: rotate(-11.27deg);
  -moz-transform: rotate(-11.27deg);
  -ms-transform: rotate(-11.27deg);
  -o-transform: rotate(-11.27deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.rotateLuck {
  transform: rotate(-5.55deg);
  -webkit-transform: rotate(-5.55deg);
  -moz-transform: rotate(-5.55deg);
  -ms-transform: rotate(-5.55deg);
  -o-transform: rotate(-5.55deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.winnerText {
  min-height: 2.5rem;
}
.rules-text {
  min-height: 2.5rem;
}
.rule-text {
  font-size: 0.8rem;
}
#webpushr-bell-optin {
  display: block !important;
}
.ruleNumber {
  min-width: 2.25rem;
}
.ruleText {
  font-size: 0.8rem;
}
</style>
