<template>
  <div class="wrapper">
    <div class="flex flex-col justify-center items-center pt-8">
      <img
        :src="require('@/assets/images/no-more-freebies.svg')"
        class="w-5/6"
      />
      <lottie-animation
        :path="lottiePath"
        :loop="true"
        :autoPlay="true"
        :speed="1"
        :height="220"
      />
    </div>
    <div class="w-full text-center pt-4">
      <p class="font-ZuumeSemiBold text-nColorOrange text-2xl">
        we hope you had a great time at
      </p>
      <div
        class="text-white text-5xl text-center mt-3"
        v-if="businessName && businessLocation"
      >
        <span class="font-ZuumeExtraBold business-name" ref="businessNameRef">
          {{ businessName }}
        </span>
        <div class="text-white text-base font-ZuumeSemiBold">
          {{ businessLocation }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: "AllExpsDone",
  components: { LottieAnimation },
  data() {
    return {
      businessName: this.$store.getters.businessName,
      businessLocation: this.$store.getters.businessLocation,
      businessCategory: this.$store.getters.businessCategory,
    };
  },
  mounted() {
    this.resizeText();
  },
  computed: {
    lottiePath() {
      return "lotties/gohomeda-" + this.businessCategory + ".json";
    },
  },
  methods: {
    resizeText() {
      const businessNameElement = this.$refs.businessNameRef;
      const containerWidth = businessNameElement.clientWidth;
      const originalText = this.businessName;
      let fontSize = 30; // Default font size
      const maxFontSize = 50; // Maximum font size

      // Create a temporary element to measure the width of the text
      const tempElement = document.createElement("span");
      tempElement.style.visibility = "hidden";
      tempElement.style.fontSize = fontSize + "px";
      tempElement.textContent = originalText;
      document.body.appendChild(tempElement);

      // Reduce font size until it fits within the container width
      while (tempElement.clientWidth > containerWidth && fontSize > 0) {
        fontSize--;
        tempElement.style.fontSize = fontSize + "px";
      }

      // Remove the temporary element
      document.body.removeChild(tempElement);

      // Set the final font size for the business name element
      businessNameElement.style.fontSize =
        Math.min(fontSize, maxFontSize) + "px";
    },
  },
};
</script>

<style scoped lang="postcss">
.wrapper {
  height: 100dvh;
}
</style>
